import { render, staticRenderFns } from "./LoginNew.vue?vue&type=template&id=e0bcc79e&"
import script from "./LoginNew.vue?vue&type=script&lang=ts&"
export * from "./LoginNew.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\DevOpsAgents\\Agent-05\\_work\\30\\s\\KM.Dataarkiv.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e0bcc79e')) {
      api.createRecord('e0bcc79e', component.options)
    } else {
      api.reload('e0bcc79e', component.options)
    }
    module.hot.accept("./LoginNew.vue?vue&type=template&id=e0bcc79e&", function () {
      api.rerender('e0bcc79e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/LoginNew.vue"
export default component.exports